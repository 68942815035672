import {URI_PATH} from '../../../utils/constants';
const axios = require('axios');
const qs = require('qs');

export const getQuestionApi = (Type) =>{
    let url = `${URI_PATH}app/question/step/action/getQuestion`;
    let body = {
        Type
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const saveAnswerApi = (QuestionId, Answer, Type) =>{
    let url = `${URI_PATH}app/question/step/action/saveAnswer`;
    let body = {
        QuestionId,
        Answer,
        Type
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}
