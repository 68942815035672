import React, { Component } from 'react';
import { connect } from 'react-redux';
import Countdown, { zeroPad } from 'react-countdown-now';
import Loading from '../../components/Loading'
import { withRouter } from 'react-router';
import { getCountMission, getUserItem } from '../../actions/home/';
import { formatDecimal } from '../../utils/common';
import Icon from 'react-fontawesome';

const listCharge = [
    { title: 'Tiệm kim hoàn', image: require('../../assets/image/menu/charge-asset-market.png'), link: '/menu/charge-asset-market' },
    { title: 'Chợ Gold[z]', image: require('../../assets/image/menu/charge-blackmarket.png'), link: '/menu/charge-blackmarket' },
    { title: 'Chuyển nhượng', image: require('../../assets/image/menu/charge.png'), link: '/menu/charge' },
    { title: 'Thuê cửa hàng', image: require('../../assets/image/menu/charge-sell.png'), link: '/menu/charge-sell' },
    { title: 'Siêu thị', image: require('../../assets/image/menu/charge-market.png'), link: '/menu/charge-market' },
    { title: 'Chợ ẩm thực', image: require('../../assets/image/menu/charge-food.png'), link: '/menu/charge-food' },
    { title: 'Chợ vật liệu', image: require('../../assets/image/menu/charge-asset.png'), link: '/menu/charge-asset' },
]

const listCommunity = [
    { title: 'BXH Phú hộ', image: require('../../assets/image/menu/brand-top.png'), link: '/menu/brand-top' },
    { title: 'Tặng quà', image: require('../../assets/image/menu/gift-offer.png'), link: '/menu/gift-offer', notify: 'OfferCount' },
    { title: 'Đang Online', image: require('../../assets/image/menu/brand-online.png'), link: '/menu/brand-online', mission: 'LikeCount' },
    { title: 'Vòng tròn học tập', image: require('../../assets/image/menu/charge-affiliate.png'), link: '/menu/charge-affiliate', notify: 'AffiliateCount' },
    { title: 'Ghép nhóm thành tựu', image: require('../../assets/image/menu/brand-group.png'), link: '/menu/brand-group', mission: 'GroupCount' },
]

const listClub = [
    { title: 'Thông tin nhóm', image: require('../../assets/image/menu/club-info.png'), link: '/menu/club-info' },
    { title: 'Giao lưu', image: require('../../assets/image/menu/club-feast.png'), link: '/menu/club-feast', mission: 'FeastClubCount' },
    { title: 'Phát lộc', image: require('../../assets/image/menu/club-fortune.png'), link: '/menu/club-fortune', notify: 'FortuneClubCount' },
    { title: 'Thăng hạng', image: require('../../assets/image/menu/club-level.png'), link: '/menu/club-level', mission: 'ContributeClubCount' },
    { title: 'DS Nhóm', image: require('../../assets/image/menu/club-list.png'), link: '/menu/club-list' },
]

const listClubNoJoin = [
    { title: 'Tạo nhóm', image: require('../../assets/image/menu/club-info.png'), link: '/menu/club-create' },
    { title: 'DS Nhóm', image: require('../../assets/image/menu/club-list.png'), link: '/menu/club-list' },
]

const listSetting = [
    { title: 'Thông tin', image: require('../../assets/image/menu/setting-profile.png'), link: '/menu/setting-profile' },
    { title: 'Bảo mật', image: require('../../assets/image/menu/setting-secure.png'), link: '/menu/setting-secure' },
    { title: 'Lịch sử hoạt động', image: require('../../assets/image/menu/setting-history.png'), link: '/menu/setting-history' },
    { title: 'Hình nền', image: require('../../assets/image/menu/config-wallpaper.png'), link: '/menu/config-wallpaper' },
]

const listPractise = [
    { title: 'Lịch sự kiện', image: require('../../assets/image/menu/calendar.png'), link: '/menu/guide' },
    { title: 'Thành tích', image: require('../../assets/image/menu/achievements.png'), link: '/menu/vestige', queue: 'SumVestige' },
    { title: 'Mục tiêu', image: require('../../assets/image/menu/target.png'), link: '/menu/target', queue: 'Target' },
    { title: 'Năng lực', image: require('../../assets/image/menu/capacity.png'), link: '/menu/capacity', queue: 'AlphaLevel' },
    { title: 'Huy chương Z', image: require('../../assets/image/crest.png'), link: '/menu/medal', mission: 'SumMission' },
]

const listAsset = [
    { title: 'Nhà bếp', image: require('../../assets/image/menu/kitchen.png'), link: '/home?tab=kitchen', otherAction: 'kitchen' },
    { title: 'Nhà kho', image: require('../../assets/image/menu/storehouse.png'), link: '/home?tab=storehouse', otherAction: 'storehouse' },
    { title: 'Két sắt', image: require('../../assets/image/menu/stock.png'), link: '/home?tab=stock', otherAction: 'stock', property: 'Invest' },
    { title: 'Phòng ngủ', image: require('../../assets/image/menu/bedroom.png'), link: '/home?tab=bedroom', otherAction: 'bedroom', queue: 'Bedroom' },
    { title: 'Phòng khách', image: require('../../assets/image/menu/living-room.png'), link: '/home?tab=livingRoom', otherAction: 'livingRoom', property: 'LivingRoom' },
]

class SearchListContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    checkPathnameClass = (link) => {
        if (this.props.history?.location?.pathname + this.props.history?.location?.search === link) {
            return 'bg-success'
        }
        return 'bg-primary'
    }

    renderListItem = (list) => {
        const { countMission, countNotify, countQueue, countProperty, questionLobby, questionEnglish, questionBoss } = this.props;
        return list.map((item, key) =>
            <div
                className={`item-search-container relative ${this.checkPathnameClass(item.link)}`}
                key={key}
                onClick={() => this.props.navigateToScreen(item.link, item.otherAction)}
            >
                <div className='icon-item-search-container'>
                    <img
                        src={item.image}
                        className='icon-item-search'
                    />
                </div>
                <div className='text-item-search'>
                    <p className='mgb-0'>{item.title}</p>
                    {item.property && parseInt(countProperty?.[item.property]) > 0 && (
                        <>
                            {item.property === 'Invest' ?
                                <p className='mgb-0 text-time'>{countProperty[item.property]} cổ phiếu</p>
                                :
                                <p className='mgb-0 text-time'>{formatDecimal(countProperty[item.property])} đ[z]</p>
                            }
                        </>
                    )}
                    {item.questionLobby && +questionLobby?.MaxCorrect > 0 && (
                        <>
                            <p className='mgb-0 text-time'>Kỷ lục hôm nay: {questionLobby?.FullName}</p>
                        </>
                    )}
                    {item.questionEnglish && +questionEnglish?.MaxCorrect > 0 && (
                        <>
                            <p className='mgb-0 text-time'>Kỷ lục hôm nay: {questionEnglish?.FullName}</p>
                        </>
                    )}
                    {item.time == 'questionBoss' &&
                        <>
                            {questionBoss !== undefined ? (
                                <p className='mgb-0'>
                                    {+questionBoss == 0 ? (<span className='text-time'>Đang diễn ra</span>) :
                                        (<Countdown
                                            date={questionBoss * 1000}
                                            renderer={({ days, hours, minutes, seconds }) => (<span className='text-time'>{zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}</span>)}
                                            onComplete={this.props.getCountMission}
                                        />)
                                    }
                                </p>
                            ) :
                                <p className='mgb-0 text-time'>--:--:--</p>
                            }
                        </>
                    }
                </div>
                {
                    item.mission && parseInt(countMission?.[item.mission]) > 0 && (
                        <div className='count-mission-new'>
                            <p className='mgb-0'>{countMission[item.mission]}</p>
                        </div>
                    )
                }
                {
                    item.notify && parseInt(countNotify?.[item.notify]) > 0 && (
                        <div className='count-notify-new'>
                            <p className='mgb-0'>{countNotify[item.notify]}</p>
                        </div>
                    )
                }
                {
                    item.queue && parseInt(countQueue?.[item.queue]) > 0 && (
                        <div className='count-queue-new'>
                            <img
                                src={require('../../assets/image/triangle.png')}
                                className='count-queue-img'
                            />
                            <p className='mgb-0 count-queue-text'>{countQueue[item.queue]}</p>
                        </div>
                    )
                }
                {+ questionLobby?.MaxCorrect > 0 && item.questionLobby &&
                    <div className='count-queue-new'>
                        <Icon name='flag' className='question-lobby-cup-icon' />
                        <p className='text-question-lobby-cup'>{questionLobby.MaxCorrect}</p>
                    </div>
                }
                {
                    +questionEnglish?.MaxCorrect > 0 && item.questionEnglish &&
                    <div className='count-queue-new'>
                        <Icon name='flag' className='question-lobby-cup-icon' />
                        <p className='text-question-lobby-cup'>{questionEnglish.MaxCorrect}</p>
                    </div>
                }
            </div>
        )
    }

    render() {
        const { listGuide, isLoadingGuide, dataUser, onComplete } = this.props;
        const guideSoon = listGuide.filter(item => item.timeQueue > 0)[0]
        const listGuideCurrent = listGuide.filter(item => item.timeQueue == 0)
        return (
            <>
                {isLoadingGuide ?
                    <Loading small />
                    :
                    <>
                        <p className='text-large mgb'>Sắp diễn ra</p>
                        <div
                            className={`item-search-container ${this.checkPathnameClass(guideSoon.link)}`}
                            onClick={() => this.props.navigateToScreen(guideSoon.link)}
                        >
                            <div className='icon-item-search-container'>
                                <img
                                    src={guideSoon.image}
                                    className='icon-item-search'
                                />
                            </div>
                            <div className='text-item-search'>
                                <p className='mgb-0'>{guideSoon.title}</p>
                                <p className='text-time'>Diễn ra sau <Countdown
                                    date={guideSoon.timeQueue * 1000}
                                    renderer={({ days, hours, minutes, seconds }) => (<span className='text-time'>{zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}</span>)}
                                    onComplete={() => onComplete && onComplete()}
                                /></p>
                            </div>
                        </div>
                        <p className='text-large mgt-2 mgb'>Đang diễn ra</p>
                        {this.renderListItem(listGuideCurrent)}
                        <p className='text-large mgt-2 mgb'>Rèn luyện</p>
                        {this.renderListItem(listPractise)}
                        <p className='text-large mgt-2 mgb'>Tài sản</p>
                        {this.renderListItem(listAsset)}
                        <p className='text-large mgt-2 mgb'>Tương tác</p>
                        {this.renderListItem(listCommunity)}
                        <p className='text-large mgt-2 mgb'>Hoạt động nhóm</p>
                        {this.renderListItem(dataUser.ClubId > 0 ? listClub : listClubNoJoin)}
                        <p className='text-large mgt-2 mgb'>Thị trường</p>
                        {this.renderListItem(listCharge)}
                        <p className='text-large mgt-2 mgb'>Cài đặt</p>
                        {this.renderListItem(listSetting)}
                    </>
                }
            </>
        )
    }
}

const mapStateToProps = (state) => {
    const { listGuide, isLoadingGuide } = state.getListGuide;
    const { countMission, countNotify, countQueue, countProperty, questionLobby, questionEnglish, questionBoss } = state.getCountMission;
    const { dataUser } = state.getUserData;
    return { listGuide, isLoadingGuide, dataUser, countMission, countNotify, countQueue, countProperty, questionLobby, questionEnglish, questionBoss }
}

export default connect(mapStateToProps, {
    getUserItem,
    getCountMission
})(withRouter(SearchListContainer))