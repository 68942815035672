import React, { Component } from 'react';
import Loading from '../Loading';
import { getBonusEveryDayApi, getUserPrestigeApi, receiveBonusApi } from '../../api/home';
import { connect } from 'react-redux';
import { showNotification, showNotificationBonus } from '../../utils/notification';
import { MESSAGE, PROP } from '../../utils/constants';
import Icon from 'react-fontawesome';
import { Button, Modal, Tooltip } from 'antd';
import { formatDecimal, getAvatarFb } from '../../utils/common';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import StudySummary from '../../screens/study/StudySummary';
import PointProperty from '../brand/PointProperty';
import { changePoint, getUserData, getCountMission } from '../../actions/home/';
import TooltipUser from '../TooltipUser';

class ListVestige extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userVestige: {},
            isLoadingUserVestige: true,
            userProperty: {},
            chartData: [{
                name: '',
                data: [0, 0, 0, 0, 0, 0],
            }],
            userBonus: {},
            pressBonusType: ''
        }
        this.userId = this.props.userId || this.props.dataUser.UserId;
    }

    getUserVestige = () => {
        getUserPrestigeApi(this.userId).then(res => {
            if (res.code == 1) {
                const userProperty = res.userProperty;
                this.setState({
                    userVestige: res.userVestige,
                    isLoadingUserVestige: false,
                    userProperty,
                    chartData: [{
                        name: userProperty.FullName,
                        data: [userProperty.LeaderPoint, userProperty.ManagePoint, userProperty.RelationPoint, userProperty.FundPoint, userProperty.ExpertisePoint, userProperty.LanguagePoint]
                    }]
                })
            }
            else showNotification(res.message, res.code);
        }).catch(e => {
            showNotification(MESSAGE.ERROR, 0);
        })
    }

    renderContentTooltip = (type) => {
        switch (type) {
            case 'bonusVip':
                return (
                    <p className='mgt'>Thu nhập = VIP x 100.000 đ[z]</p>
                )
            case 'bonusAchievement':
                return (
                    <>
                        <p className='mgt'>Thu nhập = Thành tựu x 10 đ[z]</p>
                    </>
                )
            case 'bonusLike':
                return (
                    <>
                        <p className='mgt'>Thu nhập = Thương hiệu x 100 đ[z]</p>
                    </>
                )
            case 'bonusArmy':
                return (
                    <>
                        <p className='mgt'>Bậc lương Quân hàm x VIP</p>
                        <div className='mgt mgb'>
                            <hr />
                        </div>
                        <p className='mgt'>Bậc lương quân hàm</p>
                        <p className='text-left'>• Binh nhì: 100.000 đ[z]/ngày</p>
                        <p className='text-left'>• Binh nhất: 200.000 đ[z]/ngày</p>
                        <p className='text-left'>• …</p>
                        <p className='mgt-2'>(Bước tăng 100.000 đ[z])</p>
                    </>
                )
            case 'bonusAff':
                return (
                    <>
                        <p className='mgt'>Thu nhập = Thu nhập từ cấp dưới</p>
                    </>
                )
            case 'bonusCrest':
                const listBonus = [
                    { crest: '0 ~ 50', bonus: 1000000 },
                    { crest: '51 ~ 100', bonus: 500000 },
                    { crest: '101 ~ 150', bonus: 250000 },
                    { crest: '151 ~ 200', bonus: 125000 },
                    { crest: '200 ~ 250', bonus: 62500 },
                    { crest: '251 ~ 300', bonus: 31250 },
                    { crest: '> 301', bonus: 15625 },
                ]
                return (
                    <>
                        <p className='mgt'>Thu nhập theo tích luỹ số lượng Huy chương</p>
                        <hr />
                        {listBonus.map((item, key) =>
                            <div className='row' key={key}>
                                <div className='col-md-6 col-sm-6 col-xs-6 flex flex-start'>
                                    <p className='mgt'>HC: {item.crest}</p>
                                </div>
                                <div className='col-md-6 col-sm-6 col-xs-6 flex flex-end'>
                                    <p className='mgt'>+ {formatDecimal(item.bonus)} đz/hc</p>
                                </div>
                            </div>
                        )}
                    </>
                )
        }
        return null
    }

    renderTooltip = (data) => {
        return (
            <div className='item-center'>
                <img src={data.image} className='vestige-image' />
                <p className='mgt'>Công thức tính thu nhập thụ động</p>
                {this.renderContentTooltip(data.tooltip)}
            </div>
        )
    }

    showModalStudySummary = () => {
        this.setState({
            showModal: true
        })
    }

    getBonusEveryDay = () => {
        getBonusEveryDayApi().then(res => {
            if (res.code == 1) {
                this.setState({
                    userBonus: res.data,
                })
            }
            else showNotification(MESSAGE.ERROR, 0);
        }).catch(e => {
            showNotification(MESSAGE.ERROR, 0);
        })
    }

    receiveBonus = (bonusType) => {
        if (!this.state.pressBonusType) {
            this.setState({ pressBonusType: bonusType });
            const receiveBonusType = 'IsReceived' + bonusType;
            receiveBonusApi(bonusType).then(res => {
                if (res.code == 1) {
                    this.setState({
                        userBonus: {
                            ...this.state.userBonus,
                            [receiveBonusType]: true
                        }
                    })
                    showNotificationBonus(res.balanceZ, 1, 3);
                    this.props.changePoint([null, null, res.balanceZ]);
                    this.props.getCountMission();
                }
                showNotification(res.message, res.code);
                this.setState({ pressBonusType: '' })
            }).catch(e => {
                showNotification(MESSAGE.ERROR, 0);
                this.setState({ pressBonusType: '' })
            })
        }
    }

    componentDidMount() {
        this.getUserVestige();
        !this.props.userId && this.getBonusEveryDay();
    }

    render() {
        const { userVestige, isLoadingUserVestige, showModal, userProperty, chartData, userBonus, pressBonusType } = this.state;
        const { dataUser } = this.props;
        const vestigeData = [
            { image: require('../../assets/image/calendar.png'), title: 'Ngày tham gia', data: userVestige.CreateDate },
            { image: require('../../assets/image/crest.png'), title: 'Thu nhập thụ động từ Huy Chương Z', data: userVestige.BonusCrest, tooltip: 'bonusCrest', isReceived: userBonus?.IsReceivedCrestBonus, bonusType: 'CrestBonus' },
            { image: require('../../assets/image/vip.png'), title: 'Thu nhập thụ động từ cấp độ VIP', data: userVestige.BonusVip, tooltip: 'bonusVip', isReceived: userBonus?.IsReceivedVipBonus, bonusType: 'VipBonus' },
            { image: require('../../assets/image/achievement.png'), title: 'Thu nhập thụ động từ thành tựu', data: userVestige.BonusAchievement, tooltip: 'bonusAchievement', isReceived: userBonus?.IsReceivedAchievementBonus, bonusType: 'AchievementBonus' },
            { image: require('../../assets/image/like.png'), title: 'Thu nhập thụ động từ thương hiệu', data: userVestige.BonusUserLike, tooltip: 'bonusLike', isReceived: userBonus?.IsReceivedLikeBonus, bonusType: 'LikeBonus' },
            { image: require('../../assets/image/army.png'), title: 'Thu nhập thụ động từ quân hàm', data: userVestige.BonusArmy, tooltip: 'bonusArmy', isReceived: userBonus?.IsReceivedArmyBonus, bonusType: 'ArmyBonus' },
            { image: require('../../assets/image/menu/charge-affiliate.png'), title: 'Thu nhập thụ động từ vòng tròn học tập', data: userVestige.BonusAff, tooltip: 'bonusAff' },
            { image: require('../../assets/image/sword-vs.png'), title: 'Đấu trường khởi nghiệp', data: `${userVestige.WinFight}/${userVestige.AllFight}` },
            { image: require('../../assets/image/menu/gift-offer.png'), title: 'Tặng quà bạn bè', data: `${userVestige.GiftOffer}` },
            { image: require('../../assets/image/menu/gift-open.png'), title: 'Thử thách dậy sớm', data: `${userVestige.GiftDay}` },
            { image: require('../../assets/image/z-alpha-active.png'), title: 'Level thử thách Z Alpha', data: `${userVestige.LevelAlpha}` },
            { image: require('../../assets/image/menu/study-summary.png'), title: 'Bảng điểm', data: `actionStudySummary` },
            { image: require('../../assets/image/menu/gift-ticket.png'), title: 'Phá phong ấn', data: `${userVestige.GiftTicket}` },
            { image: require('../../assets/image/menu/question-event.png'), title: 'Ngoại ngữ hằng ngày', data: `${userVestige.GiftEvent}/${userVestige.AllGiftEvent} (${(Math.round(userVestige.GiftEvent / userVestige.AllGiftEvent * 10000) / 100) || 0} %)` },
            { image: require('../../assets/image/menu/question-train.png'), title: `Toán khởi nghiệp`, data: `${userVestige.GiftTrain}/${userVestige.AllGiftTrain} (${(Math.round(userVestige.GiftTrain / userVestige.AllGiftTrain * 10000) / 100) || 0} %)` },
            { image: require('../../assets/image/menu/gift-spin.png'), title: 'Mở quà thần bí', data: `${userVestige.GiftSpin}` },
            { image: require('../../assets/image/menu/gift-effort.png'), title: 'Mở quà tăng trưởng', data: `${userVestige.GiftEffort}` },
            // { image: require('../../assets/image/menu/question-own.png'), title: 'Đóng góp câu hỏi', data: `${userVestige.QuestionAdd}/${userVestige.AllQuestionAdd}` },
            // { image: require('../../assets/image/menu/publish-post.png'), title: 'Đóng góp bài viết', data: `${userVestige.Post}/${userVestige.AllPostPublish}` },
            // { image: require('../../assets/image/menu/publish-video.png'), title: 'Đóng góp video', data: `${userVestige.Video}/${userVestige.AllVideoPublish}` },
        ]
        if (isLoadingUserVestige) return <Loading small />
        return (
            <>
                <div className='item-center'>
                    <img src={getAvatarFb(userVestige.FacebookId)} className='avatar-guest-huge' />
                    <p>
                        {userVestige.FullName}
                        <Icon
                            name='pencil'
                            style={{ marginLeft: '1vh', fontSize: '2.8vh' }}
                            className='pointer'
                            onClick={() => this.props.history.push('/menu/setting-profile')}
                        />
                    </p>
                    <p>{userVestige.Gender == 1 ? 'Nữ' : 'Nam'} {moment(userVestige.BirthDay, "YYYY-MM-DD HH:mm").format("DD-MM-YYYY")}</p>
                    {userVestige.UserAff &&
                        <>
                            <p>Được kết nối bởi
                                <TooltipUser
                                    userInfo={userVestige.UserAff}
                                    customComponent={
                                        <span className='bold'><u> {userVestige.UserAff.FullName}</u></span>
                                    }
                                />
                            </p>
                        </>

                    }
                </div>
                {vestigeData.map((item, key) =>
                    <div className='flex-row box-primary align-center' key={key}>
                        <div className='flex-3 item-center'>
                            <img
                                src={item.image}
                                className='vestige-image'
                            />
                        </div>
                        <div className='flex-5'>
                            <p className='mgb-0'>{item.title}</p>
                        </div>
                        <div className='flex-3 align-center' style={{ justifyContent: 'space-between' }}>
                            {item.data == 'actionStudySummary' ?
                                <div className='item-center'>
                                    <Button
                                        className='button-done-register-study bg-success'
                                        onClick={this.showModalStudySummary}
                                    >
                                        Xem
                                    </Button>
                                </div>
                                :
                                <>
                                    <div>
                                        <p>{item.data || ''}</p>
                                        {typeof item.isReceived == "boolean" &&
                                            <>
                                                {item.isReceived == 0 ?
                                                    <Button
                                                        className='button-done-register-study bg-success'
                                                        onClick={() => this.receiveBonus(item.bonusType)}
                                                        loading={pressBonusType == item.bonusType}
                                                    >
                                                        Nhận tiền
                                                    </Button>
                                                    :
                                                    <Button className='button-done-register-study bg-primary'>
                                                        Đã nhận
                                                    </Button>
                                                }
                                            </>
                                        }
                                    </div>
                                    {item.tooltip &&
                                        <Tooltip
                                            overlayClassName='tooltip-user'
                                            key={key}
                                            overlayStyle={{ zIndex: 99999 }}
                                            title={this.renderTooltip(item)}
                                        >
                                            <Icon name='question-circle' style={{ fontSize: '3vh' }} />
                                        </Tooltip>
                                    }
                                </>
                            }
                        </div>
                    </div>
                )}
                <PointProperty userProperty={userProperty} chartData={chartData} />
                <Modal
                    {...PROP.MODAL}
                    visible={showModal}
                    onCancel={() => this.setState({ showModal: false })}
                    width={'120vh'}
                >
                    {showModal &&
                        <StudySummary userId={this.userId} isGuest={true} />
                    }
                </Modal>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    return { dataUser };
}

export default connect(mapStateToProps, { changePoint, getUserData, getCountMission })(withRouter(ListVestige))